@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.popup-container {
    width: auto;
    min-width: 350px;
}
